<template>
  <admin-status-button
    v-if="userRole.id === rolesIds.admin"
    :order="order"
  />
  <stock-manager-status-button
    v-else-if="userRole.id === rolesIds.stockManager"
    :order-status-ids="orderStatusIds"
    :order="order"
  />
  <operator-status-button
    v-else-if="userRole.id === rolesIds.operator"
    :order-status-ids="orderStatusIds"
    :order="order"
  />
  <entity-admin-status-button
    v-else-if="userRole.id === rolesIds.entityAdmin"
    :order="order"
  />
</template>

<script>
import { orderStatusIds } from '@/orderStatus'
import { rolesIds } from '@/roles'

import AdminStatusButton from '@/views/dashboard/components/ChangeStatusButton/roles/AdminStatusButton'
import StockManagerStatusButton from '@/views/dashboard/components/ChangeStatusButton/roles/StockManagerStatusButton'
import OperatorStatusButton from '@/views/dashboard/components/ChangeStatusButton/roles/OperatorStatusButton'
import EntityAdminStatusButton from '@/views/dashboard/components/ChangeStatusButton/roles/EntityAdminStatusButton'

export default {
  name: 'ChangeStatusButton',
  components: { EntityAdminStatusButton, OperatorStatusButton, StockManagerStatusButton, AdminStatusButton },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      orderStatusIds: orderStatusIds,
      rolesIds: rolesIds,
      showMenu: false,
    }
  },

  computed: {
    userRole () {
      return this.$store.getters.getUserRole
    },
  },
  methods: {},
  i18n: {
    messages: {
      en: {
        c: {
          menu: {
            optionOne: 'Open Ticket',
            optionTwo: 'Close Ticket',
          },
        },

      },
      it: {
        c: {
          menu: {
            optionOne: 'Apri Ticket',
            optionTwo: 'Chiudere Ticket',
          },
        },

      },
    },
  },
}
</script>

<style scoped>

</style>
