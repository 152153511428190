<template>
  <span>
    {{ $t(order.current_status.slug) | capitalize }}
  </span>
</template>

<script>
export default {
  name: 'AdminStatusButton',
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style scoped>

</style>
