export const orderStatusIds = {
  orderCreated: 1,
  stockMissing: 2,
  stockFulfilled: 3,
  packaging: 4,
  packaged: 5,
  shipped: 6,
  delivered: 7,
  returned: 8,
  cancelled: 9,
  finished: 10,
}
