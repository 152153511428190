<template>
  <base-status-button :text="$t('c.text')">
    <template v-slot:menuContent>
      <v-list>
        <v-list-item
          @click="shipped"
        >
          <v-list-item-title>
            <a>{{ $t('c.menu.optionOne') }}</a>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </template>
  </base-status-button>
</template>

<script>
import OrderActionsRepository from '@/api/OrderActionsRepository'
import BaseStatusButton from '@/views/dashboard/components/ChangeStatusButton/status/BaseStatusButton'
import EventBus from '@/plugins/EventBus'

export default {
  name: 'Returned',
  components: { BaseStatusButton },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  methods: {
    shipped () {
      OrderActionsRepository.shipped(this.order.id)
        .then(({ data }) => {
          EventBus.$emit('orderStatusUpdated', { id: this.order.id, status: data })
        })
        // eslint-disable-next-line handle-callback-err
        .catch(error => {

        })
    },
  },
  i18n: {
    messages: {
      en: {
        c: {
          text: 'returned',
          menu: {
            optionOne: 'shipped',
          },
        },
      },
      it: {
        c: {
          text: 'restituito',
          menu: {
            optionOne: 'spedito',
          },
        },
      },
    },
  },
}
</script>

<style scoped>

</style>
