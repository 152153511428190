<template>
  <base-status-button :text="$t('c.text')">
    <template v-slot:menuContent>
      <v-list>
        <v-list-item
          @click="delivered"
        >
          <v-list-item-title>
            <a>{{ $t('c.menu.optionOne') }}</a>
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          @click="returned"
        >
          <v-list-item-title>
            <a>{{ $t('c.menu.optionTwo') }}</a>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </template>
  </base-status-button>
</template>

<script>
import OrderActionsRepository from '@/api/OrderActionsRepository'
import BaseStatusButton from '@/views/dashboard/components/ChangeStatusButton/status/BaseStatusButton'
import EventBus from '@/plugins/EventBus'

export default {
  name: 'Shipped',
  components: { BaseStatusButton },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  methods: {
    delivered () {
      OrderActionsRepository.delivered(this.order.id)
        .then(({ data }) => {
          EventBus.$emit('orderStatusUpdated', { id: this.order.id, status: data })
        })
        // eslint-disable-next-line handle-callback-err
        .catch(error => {
        })
    },
    returned () {
      OrderActionsRepository.returned(this.order.id)
        .then(({ data }) => {
          EventBus.$emit('orderStatusUpdated', { id: this.order.id, status: data })
        })
        // eslint-disable-next-line handle-callback-err
        .catch(error => {

        })
    },
  },
  i18n: {
    messages: {
      en: {
        c: {
          text: 'shipped',
          menu: {
            optionOne: 'delivered',
            optionTwo: 'returned',
          },
        },

      },
      it: {
        c: {
          text: 'spedito',
          menu: {
            optionOne: 'consegnato',
            optionTwo: 'restituito',
          },
        },
      },
    },
  },
}
</script>

<style scoped>

</style>
