<template>
  <div>
    <packaged
      v-if="order.current_status.id === orderStatusIds.packaged"
      :order="order"
    />
    <shipped
      v-else-if="order.current_status.id === orderStatusIds.shipped"
      :order="order"
    />
    <returned
      v-else-if="order.current_status.id === orderStatusIds.returned"
      :order="order"
    />
    <delivered
      v-else-if="order.current_status.id === orderStatusIds.delivered"
      :order="order"
    />
    <span v-else>
      {{ $t(order.current_status.slug) | capitalize }}
    </span>
  </div>
</template>

<script>

import Packaged from '@/views/dashboard/components/ChangeStatusButton/status/Packaged'
import Shipped from '@/views/dashboard/components/ChangeStatusButton/status/Shipped'
import Returned from '@/views/dashboard/components/ChangeStatusButton/status/Returned'
import Delivered from '@/views/dashboard/components/ChangeStatusButton/status/Delivered'
export default {
  name: 'StockManagerStatusButton',
  components: { Delivered, Returned, Shipped, Packaged },
  props: {
    order: {
      type: Object,
      required: true,
    },
    orderStatusIds: {
      type: Object,
      required: true,
    },
  },
}
</script>
<style scoped>

</style>
