<template>
  <div>
    <packaging
      v-if="order.current_status.id === orderStatusIds.packaging"
      :order="order"
    />
    <span v-else>
      {{ $t(order.current_status.slug) | capitalize }}
    </span>
  </div>
</template>

<script>
import Packaging from '@/views/dashboard/components/ChangeStatusButton/status/Packaging'
export default {
  name: 'OperatorStatusButton',
  components: { Packaging },
  props: {
    order: {
      type: Object,
      required: true,
    },
    orderStatusIds: {
      type: Object,
      required: true,
    },
  },
}
</script>
<style scoped>

</style>
