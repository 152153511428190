import Repository from './Repository'

function buildUrl (orderId, path) {
  return `api/order-actions/${orderId}/${path}`
}

export default {
  packaged (orderId) {
    return Repository.get(buildUrl(orderId, 'packaged'))
  },
  shipped (orderId) {
    return Repository.get(buildUrl(orderId, 'shipped'))
  },
  delivered (orderId) {
    return Repository.get(buildUrl(orderId, 'delivered'))
  },
  returned (orderId) {
    return Repository.get(buildUrl(orderId, 'returned'))
  },
  cancelled (orderId) {
    return Repository.get(buildUrl(orderId, 'cancelled'))
  },
  finished (orderId) {
    return Repository.get(buildUrl(orderId, 'finished'))
  },
}
