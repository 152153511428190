<template>
  <span>
    {{ order.current_status.slug | capitalize }}
  </span>
</template>

<script>
export default {
  name: 'EntityAdminStatusButton',
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style scoped>

</style>
