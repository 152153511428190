<template>
  <div>
    <v-menu
      v-model="showMenu"
      absolute
      offset-y
      style="max-width: 600px"
    >
      <template v-slot:activator="{ on, attrs }">
        <button
          class="dropbtn d-flex primary justify-content-between"
          v-bind="attrs"
          v-on="on"
        >
          <span class="ml-2">
            {{ $t(text.toLowerCase()) }}
          </span>
          <v-icon dark>
            mdi-menu-down
          </v-icon>
        </button>
      </template>

      <slot name="menuContent" />
    </v-menu>
  </div>
</template>

<script>

export default {
  name: 'BaseStatusButton',
  props: {
    text: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      showMenu: false,
    }
  },
}
</script>

<style scoped>
.dropbtn {

  color: white;
  padding: 3px;
  border-radius: 4px;
  width: 150px;
  border: none;
  cursor: pointer;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f1f1f1
}

.dropdown:hover .dropdown-content {
  display: block;
}

</style>
